import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  setWraper,
  getAll,
  deleted,
  create,
  update,
} from '../../actions'
import { BsPlus } from 'react-icons/bs'
import Loading from '../../components/Loading'
import Button from '../../components/Button'
import MaterialTable from 'material-table'
import moment from 'moment-timezone'

moment.tz.setDefault('America/Mexico_City')

const Dates = props => {
  const { localization, dates, user, doctors } = props

  useEffect(() => {
    const topbar = {
      title: 'Estudios',
      menu: { Estudios: '/studies' },
    }
    props.setTitle(topbar)
    props.setWraper(true)
    props.getAll('dates', 'GET_DATES').then(() => {
      props.getAll(`doctors`, 'GET_DOCTORS')
    })
    // eslint-disable-next-line
  }, [])

  const handleConfirmed = id => {
    props
      .update(`dates/confirmed/${id}`, 'CONFIRMED', id)
      .then(() => props.getAll('dates', 'GET_DATES'))
  }

  const editable = {
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          props
            .deleted(`dates/${oldData.id}`, 'DELETE_STUDY')
            .then(() => props.getAll('dates', 'GET_DATES'))
            .then(() => resolve())
        }, 1000)
      }),
  }

  if (localization && dates && doctors) {
    const lookupDoctors = {}
    doctors.map(m => (lookupDoctors[m.user_id] = m.name))

    return (
      <>
        <MaterialTable
          title="Estudios"
          columns={[
            {
              title: 'Doctor',
              field: 'user_id',
              lookup: lookupDoctors,
            },
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Teléfono',
              field: 'phone',
            },
            {
              title: 'Fecha',
              field: 'date',
            },

            {
              title: 'Confirmar',
              field: 'name',
              editable: 'never',
              render: rowData =>
                rowData.confirmed === 0 ? (
                  <Button onClick={() => handleConfirmed(rowData.id)}>
                    <BsPlus />
                  </Button>
                ) : (
                  'Confirmada'
                ),
            },
          ]}
          localization={localization}
          data={
            user.role === 'Doctor'
              ? dates
                  .filter(d => parseInt(d.user_id) === parseInt(user.id) && d.verify === 1)
                  .map(d => {
                    return {
                      ...d,
                      date: moment.utc(d.date).format('DD-MM-YYYY HH:mm:ss'),
                    }
                  })
              : dates
                  .filter(d => d.verify === 1)
                  .map(d => {
                    return {
                      ...d,
                      date: moment.utc(d.date).format('DD-MM-YYYY HH:mm:ss'),
                    }
                  })
          }
          editable={editable}
          options={{ paging: false }}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    localization: state.reducerApp.localization,
    dates: state.reducerDates.dates,
    user: state.reducerApp.user,
    doctors: state.reducerDoctors.doctors,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  deleted,
  setWraper,
  update,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dates)
