import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  setTitle,
  setWraper,
  getAll,
  deleted,
  create,
  update,
} from '../../../actions'
import Loading from '../../../components/Loading'
import MaterialTable from 'material-table'

const Studies = props => {
  const { doctorServices, localization } = props
  const { id } = useParams()

  useEffect(() => {
    props.getAll(`doctors/services/${id}`, 'GET_DOCTOR_SERVICES')
    // eslint-disable-next-line
  }, [])

  const editable = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          newData.user_id = id
          props
            .create(`doctors/services/${id}`, 'CREATE_DOCTOR_SERVICE', newData)
            .then(() =>
              props.getAll(`doctors/services/${id}`, 'GET_DOCTOR_SERVICES')
            )
            .then(() => resolve())
        }, 1000)
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          delete newData.id
          props
            .update(
              `doctors/services/${oldData.id}`,
              'UPDATE_DOCTOR_SERVICE',
              newData
            )
            .then(() =>
              props.getAll(`doctors/services/${id}`, 'GET_DOCTOR_SERVICES')
            )
            .then(() => resolve())
        }, 1000)
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          props
            .deleted(`doctors/services/${oldData.id}`, 'DELETE_DOCTOR_SERVICE')
            .then(() =>
              props.getAll(`doctors/services/${id}`, 'GET_DOCTOR_SERVICES')
            )
            .then(() => resolve())
        }, 1000)
      }),
  }

  if (doctorServices) {
    return (
      <>
        <MaterialTable
          title={`Servicios`}
          columns={[
            {
              title: 'Servicio',
              field: 'name',
            },
          ]}
          localization={localization}
          data={doctorServices}
          editable={editable}
          options={{ paging: false }}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    localization: state.reducerApp.localization,
    doctorServices: state.reducerDoctors.doctorServices,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  deleted,
  setWraper,
  update,
}

export default connect(mapStateToProps, mapDispatchToProps)(Studies)
