import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FiTrash } from 'react-icons/fi'
import {
  setTitle,
  setWraper,
  getAll,
  deleted,
  createFile,
} from '../../../actions'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Swal from 'sweetalert2'
import './styles.scss'

const Studies = props => {
  const { doctorImages } = props
  const { id } = useParams()
  const { register, handleSubmit } = useForm()

  const onSubmit = data => {
    props
      .createFile(`doctors/images/${id}`, 'CREATE_DOCTOR_IMAGE', data)
      .then(() => {
        props.getAll(`doctors/images/${id}`, 'GET_DOCTOR_IMAGES')
      })
  }
  useEffect(() => {
    props.getAll(`doctors/images/${id}`, 'GET_DOCTOR_IMAGES')
    // eslint-disable-next-line
  }, [])

  const handleDeleteImage = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Este proceso no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
    }).then(result => {
      if (result.isConfirmed) {
        props
          .deleted(`doctors/images/${id}`, 'DELETE_DOCTOR_IMAGES')
          .then(() => {
            props.getAll(`doctors/images/${id}`, 'GET_DOCTOR_IMAGES')
          })
          .then(() => {
            Swal.fire('Borrado!', 'Borrado con exito.', 'success')
          })
      }
    })
  }

  if (doctorImages) {
    return (
      <>
        <Card title="Agregar Imagen">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input type="file" passRef={register()} name="image" />
            <Button type="submit">Agregar</Button>
          </form>
        </Card>
        <Card title="Galería" className="--gallery__doctors">
          {doctorImages.map(image => (
            <div className="--gallery__doctors__container">
              <span onClick={() => handleDeleteImage(image.id)}>
                <FiTrash />
              </span>
              <img
                src={`${process.env.REACT_APP_FILES}/docs/galleries/${image.image}`}
                alt="imagen_galeria"
              />
            </div>
          ))}
        </Card>
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    doctorImages: state.reducerDoctors.doctorImages,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  createFile,
  deleted,
  setWraper,
}

export default connect(mapStateToProps, mapDispatchToProps)(Studies)
