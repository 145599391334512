import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  setWraper,
  getAll,
  deleted,
  create,
  update,
} from '../../actions'
import moment from 'moment-timezone'
import Loading from '../../components/Loading'

import MaterialTable from 'material-table'

moment.tz.setDefault('America/Mexico_City')

const Dates = props => {
  const { localization, contact } = props

  useEffect(() => {
    const topbar = {
      title: 'Contacto',
      menu: { Contacto: '/contact' },
    }
    props.setTitle(topbar)
    props.setWraper(true)
    props.getAll('contact', 'GET_CONTACT')
    // eslint-disable-next-line
  }, [])

  if (contact) {
    return (
      <>
        <MaterialTable
          title="Contacto"
          columns={[
            {
              title: 'Nombre',
              field: 'name',
            },
            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Teléfono',
              field: 'phone',
            },
            {
              title: 'Comentario',
              field: 'comment',
            },
            {
              title: 'Fecha',
              field: 'created',
            },
          ]}
          localization={localization}
          data={contact.map(c => {
            return {
              ...c,
              created: moment.utc(c.created).format('DD/MM/YYYY'),
            }
          })}
          options={{ paging: false }}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    localization: state.reducerApp.localization,
    contact: state.reducerContact.contact,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  deleted,
  setWraper,
  update,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dates)
