import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AiOutlineZoomIn } from 'react-icons/ai'
import Button from '../../components/Button'
import {
  setTitle,
  setWraper,
  getAll,
  deleted,
  create,
  update,
} from '../../actions'
import Loading from '../../components/Loading'
import MaterialTable from 'material-table'

const Studies = props => {
  const { doctors, localization } = props

  useEffect(() => {
    props.getAll(`doctors`, 'GET_DOCTORS')
    // eslint-disable-next-line
  }, [])

  if (doctors) {
    return (
      <>
        <MaterialTable
          title={`Doctores`}
          columns={[
            {
              title: 'Nombre',
              field: 'name',
            },
            {
              title: 'Especialidad',
              field: 'specialty',
            },
            {
              title: 'Consultorio',
              field: 'room',
            },
            {
              title: 'Ver',
              field: 'id',
              render: rowData => (
                <a href={`profile/${rowData.user_id}`}>
                  <Button>
                    <AiOutlineZoomIn />
                  </Button>
                </a>
              ),
            },
          ]}
          localization={localization}
          data={doctors}
          options={{ paging: false }}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    localization: state.reducerApp.localization,
    doctors: state.reducerDoctors.doctors,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  deleted,
  setWraper,
  update,
}

export default connect(mapStateToProps, mapDispatchToProps)(Studies)
