const initialState = {
  topbar: { title: '', menu: { Menu: '/' } },
  loggedIn: true,
  wraper: false,
  user: {
    user: sessionStorage.getItem('user'),
    name: sessionStorage.getItem('name'),
    id: sessionStorage.getItem('id'),
    role: sessionStorage.getItem('role'),
  },
  localization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'Filas',
      firstAriaLabel: 'Primera',
      firstTooltip: 'Primera',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Siguiente',
      nextTooltip: 'Siguiente',
      lastAriaLabel: 'Ultimo',
      lastTooltip: 'Ultimo',
    },
    toolbar: {
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    header: {
      actions: 'Acciones',
    },
    body: {
      editRow: {
        deleteText: '¿Eliminar?',
        saveTooltip: 'Ok',
        cancelTooltip: 'Cancelar',
      },
      editTooltip: 'Editar',
      deleteTooltip: 'Eliminar',
      addTooltip: 'Agregar',
    },
  },
}

export default function reducerApp(state = initialState, action) {
  switch (action.type) {
    case 'SET_TITLE':
      return {
        ...state,
        topbar: action.payload,
      }
    case 'SET_WRAPER':
      return {
        ...state,
        wraper: action.payload,
      }
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggedIn: true,
      }
    case 'LOG_OUT':
      return {
        ...state,
        loggedIn: false,
      }
    default:
      return state
  }
}
