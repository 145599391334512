import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Layout from '../layout'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import Login from '../pages/Login'
import Users from '../pages/Users'
import CreateUser from '../pages/Users/CreateUser'
import UpdateUser from '../pages/Users/UpdateUser'

import Studies from '../pages/Studies'
import StudiesRequest from '../pages/Studies/StudiesRequest'
import Profile from '../pages/Profile'
import Dates from '../pages/Dates'
import ProfileServices from '../pages/Profile/ProfileServices'
import ProfileFaq from '../pages/Profile/ProfileFaq'
import ProfileAll from '../pages/Profile/all'
import ProfileGallery from '../pages/Profile/ProfileGallery'
import ProfileSchedule from '../pages/Profile/ProfileSchedule'
import Contact from '../pages/Contact'

const App = ({ loggedIn }) => {
  const role = sessionStorage.getItem('role')
  if (loggedIn) {
    if (role === 'Admin') {
      return (
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/users/create" component={CreateUser} />
              <Route exact path="/users/:id" component={UpdateUser} />
              <Route exact path="/login" component={Login} />

              <Route exact path="/studies" component={Studies} />
              <Route exact path="/studies/:id" component={StudiesRequest} />
              <Route exact path="/dates" component={Dates} />
              <Route exact path="/profile" component={ProfileAll} />
              <Route exact path="/profile/:id" component={Profile} />
              <Route
                exact
                path="/profile/services/:id"
                component={ProfileServices}
              />
              <Route exact path="/profile/faq/:id" component={ProfileFaq} />
              <Route
                exact
                path="/profile/gallery/:id"
                component={ProfileGallery}
              />
              <Route
                exact
                path="/profile/schedule/:id"
                component={ProfileSchedule}
              />
              <Route exact path="/contact" component={Contact} />

              <Route component={NotFound} />
            </Switch>
          </Layout>
        </BrowserRouter>
      )
    }
    if (role === 'Doctor') {
      return (
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/profile/:id" component={Profile} />
              <Route
                exact
                path="/profile/services/:id"
                component={ProfileServices}
              />
              <Route exact path="/dates" component={Dates} />
              <Route exact path="/profile/faq/:id" component={ProfileFaq} />
              <Route
                exact
                path="/profile/gallery/:id"
                component={ProfileGallery}
              />
              <Route
                exact
                path="/profile/schedule/:id"
                component={ProfileSchedule}
              />
              <Route component={Login} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </BrowserRouter>
      )
    }
    if (role === 'Laboratorio') {
      return (
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/studies" component={Studies} />
              <Route exact path="/studies/:id" component={StudiesRequest} />
              <Route component={Login} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </BrowserRouter>
      )
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route component={Login} />
          </Switch>
        </BrowserRouter>
      )
    }
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route component={Login} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
