import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ImLab } from 'react-icons/im'
import { FaUserMd } from 'react-icons/fa'
import { HiUsers } from 'react-icons/hi'
import { MdDateRange } from 'react-icons/md'
import { GrContact } from 'react-icons/gr'
import { setTitle } from '../../actions'
import IconHome from './IconHome/IconHome'
import './styles.scss'

const Home = props => {
  const { user } = props
  useEffect(() => {
    const topbar = {
      title: 'Home',
      menu: { Menu: '/' },
    }
    props.setTitle(topbar)
  })

  if (user.role === 'Admin') {
    return (
      <div className="Home">
        <IconHome icon={<FaUserMd />} url="/profile" text="Doctores" />
        <IconHome icon={<MdDateRange />} url="/dates" text="Citas" />
        <IconHome icon={<ImLab />} url="/studies" text="Laboratorio" />
        <IconHome icon={<GrContact />} url="/contact" text="Contacto" />
        <IconHome icon={<HiUsers />} url="/users" text="Usuarios" />
      </div>
    )
  }
  if (user.role === 'Doctor') {
    return (
      <div className="Home">
        <IconHome
          icon={<FaUserMd />}
          url={`/profile/${user.id}`}
          text="Perfil"
        />
        <IconHome icon={<MdDateRange />} url="/dates" text="Citas" />
      </div>
    )
  }
  if (user.role === 'Laboratorio') {
    return (
      <div className="Home">
        <IconHome icon={<ImLab />} url="/studies" text="Laboratorio" />
      </div>
    )
  } else {
    return <div className="Home">Sin acceso</div>
  }
}

const mapDispatchToProps = {
  setTitle,
}

const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
