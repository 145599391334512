import React from 'react'
import './styles.scss'

import Aside from '../containers/Aside/Aside'
import Topbar from '../containers/Topbar/Topbar'
import Wraper from '../containers/Wraper/Wraper'

const Layout = ({ children }) => {
  return (
    <>
      <Aside />
      <Topbar />
      <Wraper>{children}</Wraper>
    </>
  )
}

export default Layout
