import { combineReducers } from 'redux'
import reducerApp from './reducer'
import reducerUsers from '../pages/Users/reducer'
import reducerStudies from '../pages/Studies/reducer'
import reducerDoctors from '../pages/Doctors/reducer'
import reducerDates from '../pages/Dates/reducer'
import reducerContact from '../pages/Contact/reducer'

const reducer = combineReducers({
  reducerApp,
  reducerUsers,
  reducerStudies,
  reducerDoctors,
  reducerDates,
  reducerContact,
})

export default reducer
