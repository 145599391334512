import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { create, getAll } from '../../../actions'
import { useForm } from 'react-hook-form'
import Card from '../../../components/Card'
import Input from '../../../components/Input'
import Button from '../../../components/Button'

const CreateSupplier = props => {
  const { register, handleSubmit, errors } = useForm()
  const [passwordVerify, setPasswordVerify] = useState(false)
  const [password, setPassword] = useState(false)
  const onSubmit = data => {
    props.create('users', 'CREATE_USER', data)
    document.getElementById('formUser').reset()
  }

  const handlePasswordVerify = e => {
    if (password === e.target.value) {
      setPasswordVerify(true)
    } else {
      setPasswordVerify(false)
    }
  }

  return (
    <Card title="Crear Usuario">
      <form
        id="formUser"
        className="formUser"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="text"
          name="name"
          title="Nombre"
          passRef={register({ required: true })}
          placeholder={errors.name && 'Campo requerido'}
        />
        <Input
          type="text"
          name="user"
          title="Usuario"
          passRef={register({ required: true })}
          placeholder={errors.user && 'Campo requerido'}
        />
        <Input
          type="password"
          name="password"
          title="Contraseña"
          passRef={register({ required: true })}
          placeholder={errors.password && 'Campo requerido'}
          onChange={e => {
            setPassword(e.target.value)
          }}
        />
        <Input
          type="password"
          name="confirmPassword"
          title="Confirmar Contraseña"
          onChange={handlePasswordVerify}
        />

        {passwordVerify ? (
          <span className="--success">Password Correcto</span>
        ) : (
          <span className="--danger">Los password deben ser iguales</span>
        )}

        <div className="inputGroup">
          <label htmlFor="role">
            <span>Area:</span>
            <select name="role" ref={register}>
              <option value="Admin">Admin</option>
              <option value="Doctor">Doctor</option>
              <option value="Laboratorio">Laboratorio</option>
            </select>
          </label>
        </div>

        <div className="formUser__buttons">
          {passwordVerify ? (
            <Button type="submit" className="btn --success">
              Crear
            </Button>
          ) : (
            <button type="submit" className="btn --success --disabled" disabled>
              Crear
            </button>
          )}
          <Link to="/users">
            <Button className="btn --danger">Cancelar</Button>
          </Link>
        </div>
      </form>
    </Card>
  )
}

const mapDispatchToProps = {
  create,
  getAll,
}

export default connect(null, mapDispatchToProps)(CreateSupplier)
