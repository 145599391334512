import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  setTitle,
  setWraper,
  getAll,
  deleted,
  create,
  update,
} from '../../actions'
import { BsPlus } from 'react-icons/bs'
import Loading from '../../components/Loading'
import Button from '../../components/Button'
import MaterialTable from 'material-table'

const Studies = props => {
  const { localization, studies } = props

  useEffect(() => {
    const topbar = {
      title: 'Estudios',
      menu: { Estudios: '/studies' },
    }
    props.setTitle(topbar)
    props.setWraper(true)
    props.getAll('studies', 'GET_STUDIES')
    // eslint-disable-next-line
  }, [])

  const editable = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          props
            .create('studies', 'CREATE_STUDY', newData)
            .then(() => props.getAll('studies', 'GET_STUDIES'))
            .then(() => resolve())
        }, 1000)
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log(oldData, newData)
          delete newData.id
          props
            .update(`studies/${oldData.id}`, 'UPDATE_STUDY', newData)
            .then(() => props.getAll('studies', 'GET_STUDIES'))
            .then(() => resolve())
        }, 1000)
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          props
            .deleted(`studies/${oldData.id}`, 'DELETE_STUDY')
            .then(() => props.getAll('studies', 'GET_STUDIES'))
            .then(() => resolve())
        }, 1000)
      }),
  }

  if (localization && studies) {
    return (
      <>
        <MaterialTable
          title="Estudios"
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Requisitos',
              field: 'name',
              editable: 'never',
              render: rowData => (
                <Link to={`studies/${rowData.id}`}>
                  <Button>
                    <BsPlus />
                  </Button>
                </Link>
              ),
            },
          ]}
          localization={localization}
          data={studies}
          editable={editable}
          options={{ paging: false }}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    localization: state.reducerApp.localization,
    studies: state.reducerStudies.studies,
    user: state.reducerApp.user,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  deleted,
  setWraper,
  update,
}

export default connect(mapStateToProps, mapDispatchToProps)(Studies)
