import React from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { AiOutlineHome } from 'react-icons/ai'
import { FaUserMd } from 'react-icons/fa'
import { ImLab } from 'react-icons/im'
import { HiUsers } from 'react-icons/hi'
import './Aside.scss'
import logo from '../../assets/static/logo.png'

const Aside = props => {
  const { user } = props
  const { role, id } = user
  const location = useLocation()
  if (location.pathname !== '/login') {
    return (
      <aside className="aside">
        <Link to="/" className="aside__brand">
          <img src={logo} alt="logo" className="aside__brand__logo" />
        </Link>
        <nav className="aside__nav">
          <ul className="aside__nav__menu">
            <li className="aside__nav__item">
              <Link to="/" className="aside__nav__link">
                <AiOutlineHome className="aside__nav__icon" />
                <span className="aside__nav__text">Home</span>
              </Link>
            </li>
            {role === 'Doctor' ? (
              <li className="aside__nav__item">
                <Link to={`/profile/${id}`} className="aside__nav__link">
                  <FaUserMd className="aside__nav__icon" />
                  <span className="aside__nav__text">Perfil</span>
                </Link>
              </li>
            ) : role === 'Laboratorio' ? (
              <li className="aside__nav__item">
                <Link to={`/studies`} className="aside__nav__link">
                  <ImLab className="aside__nav__icon" />
                  <span className="aside__nav__text">Lab</span>
                </Link>
              </li>
            ) : (
              <>
                <li className="aside__nav__item">
                  <Link to={`profile`} className="aside__nav__link">
                    <FaUserMd className="aside__nav__icon" />
                    <span className="aside__nav__text">Doctores</span>
                  </Link>
                </li>
                <li className="aside__nav__item">
                  <Link to="/studies" className="aside__nav__link">
                    <ImLab className="aside__nav__icon" />
                    <span className="aside__nav__text">Lab</span>
                  </Link>
                </li>
                <li className="aside__nav__item">
                  <Link to="/users" className="aside__nav__link">
                    <HiUsers className="aside__nav__icon" />
                    <span className="aside__nav__text">Usuarios</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </aside>
    )
  }
  return null
}

const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
  }
}

export default connect(mapStateToProps, null)(Aside)
