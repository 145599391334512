import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  create,
  updateFile,
  getAll,
  setTitle,
  get,
  update,
} from '../../actions'
import logoDefault from '../../assets/static/logo.png'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import './styles.scss'

const CreateSupplier = props => {
  const { doctor } = props
  const { id } = useParams()
  const { register, handleSubmit, errors } = useForm()
  const [description, setDescription] = useState()
  const onSubmit = data => {
    data.description = description
    props.updateFile(`doctors/${id}`, 'UPDATE_DOCTOR', data)
  }

  useEffect(() => {
    const topbar = {
      title: 'Perfil',
      menu: {
        Información: `/profile/${id}`,
        Servicios: `/profile/services/${id}`,
        'Preguntas frecuentes': `/profile/faq/${id}`,
        Galería: `/profile/gallery/${id}`,
        Horarios: `/profile/schedule/${id}`,
      },
    }
    props.setTitle(topbar)
    props.get(`doctors/${id}`, 'GET_DOCTOR')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (doctor) {
      setDescription(doctor.description)
    }
  }, [doctor])

  if (doctor) {
    return (
      <Card title="Editar Información">
        <form
          id="formProfile"
          className="formProfile"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="text"
            name="name"
            value={doctor.name}
            title="Nombre"
            passRef={register()}
            helper={errors.name && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="specialty"
            value={doctor.specialty}
            title="Especialidad"
            passRef={register()}
            helper={errors.specialty && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="room"
            value={doctor.room}
            title="Consultorio"
            passRef={register()}
            helper={errors.room && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="schodule"
            value={doctor.schodule}
            title="Horario"
            passRef={register()}
            helper={errors.schodule && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="phone"
            value={doctor.phone}
            title="Telefono 1"
            passRef={register()}
            helper={errors.phone && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="phone2"
            value={doctor.phone2}
            title="Telefono 2"
            passRef={register()}
            helper={errors.phone2 && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="email"
            value={doctor.email}
            title="Email"
            passRef={register()}
            helper={errors.email && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="facebook"
            value={doctor.facebook}
            title="Facebook"
            passRef={register()}
            helper={errors.facebook && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="instagram"
            value={doctor.instagram}
            title="Instagram"
            passRef={register()}
            helper={errors.instagram && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="doctoralia"
            value={doctor.doctoralia}
            title="Doctoralia"
            passRef={register()}
            helper={errors.doctoralia && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <Input
            type="text"
            name="web"
            value={doctor.web}
            title="Página Web"
            passRef={register()}
            helper={errors.web && 'Campo requerido'}
            helperClassName={'--danger'}
          />
          <div></div>
          <div>
            <img
              className="--image"
              src={`${process.env.REACT_APP_FILES}/docs/doctors/${doctor.image}`}
              alt=""
            />
            <Input
              type="file"
              name="image"
              title="Imagen de perfil"
              passRef={register()}
            />
          </div>
          <div>
            <img
              className="--image"
              src={
                doctor.logo === null
                  ? logoDefault
                  : `${process.env.REACT_APP_FILES}/docs/logos/${doctor.logo}`
              }
              alt=""
            />
            <Input
              type="file"
              name="logo"
              title="Logo Doctor"
              passRef={register()}
            />
          </div>
          <div className="formProfile__textarea">
            Descripción
            <textarea
              name="description"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="formUser__buttons">
            <Button type="submit" className="btn">
              Guardar
            </Button>
            <Link to="/">
              <Button className="btn --danger">Cancelar</Button>
            </Link>
          </div>
        </form>
      </Card>
    )
  } else {
    return <Loading />
  }
}

const mapDispatchToProps = {
  create,
  getAll,
  setTitle,
  get,
  update,
  updateFile,
}

const mapStatetoProps = state => {
  return {
    doctor: state.reducerDoctors.doctor,
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(CreateSupplier)
