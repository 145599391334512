export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'GET_DOCTORS':
      return {
        ...state,
        doctors: action.payload.data,
      }
    case 'GET_DOCTOR':
      return {
        ...state,
        doctor: action.payload,
      }
    case 'GET_DOCTOR_SERVICES':
      return {
        ...state,
        doctorServices: action.payload.data,
      }
    case 'GET_DOCTOR_FAQ':
      return {
        ...state,
        doctorQa: action.payload.data,
      }
    case 'GET_DOCTOR_IMAGES':
      return {
        ...state,
        doctorImages: action.payload.data,
      }
    case 'GET_DOCTOR_SCHEDULES':
      return {
        ...state,
        doctorSchedules: action.payload.data,
      }
    case 'GET_DOCTOR_INACTIVE_DAYS':
      return {
        ...state,
        doctorsInactiveDays: action.payload.data,
      }
    default:
      return state
  }
}
