export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'GET_STUDIES':
      return {
        ...state,
        studies: action.payload.data,
      }
    case 'CREATE_STUDY':
      return {
        ...state,
      }
    case 'UPDATE_STUDY':
      const newArray = state.studies.filter(
        item => item.id !== action.payload.id
      )
      newArray.push(action.payload)
      return {
        ...state,
        studies: newArray,
      }
    case 'DELETE_STUDY':
      return {
        ...state,
        studies: state.studies.filter(item => item.id !== action.payload),
      }
    case 'GET_STUDIES_REQUEST':
      return {
        ...state,
        studiesRequest: action.payload.data,
      }
    default:
      return state
  }
}
