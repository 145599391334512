import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FiTrash } from 'react-icons/fi'
import { setTitle, setWraper, getAll, deleted, create } from '../../../actions'
import Loading from '../../../components/Loading'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Swal from 'sweetalert2'
import MaterialTable from 'material-table'
import moment from 'moment-timezone'
import './styles.scss'

moment.tz.setDefault('America/Mexico_City')

const Studies = props => {
  const { doctorSchedules, doctorsInactiveDays, localization } = props
  const { id } = useParams()
  const [monday, setMonday] = useState(false)
  const [mondaySchodule, setMondaySchodule] = useState([1])
  const [tuesday, setTuesday] = useState(false)
  const [tuesdaySchodule, setTuesdaySchodule] = useState([1])
  const [wednesday, setWednesday] = useState(false)
  const [wednesdaySchodule, setWednesdaySchodule] = useState([1])
  const [thursday, setThursday] = useState(false)
  const [thursdaySchodule, setThursdaySchodule] = useState([1])
  const [friday, setFriday] = useState(false)
  const [fridaySchodule, setFridaySchodule] = useState([1])
  const [saturday, setSaturday] = useState(false)
  const [saturdaySchodule, setSaturdaySchodule] = useState([1])
  const [sunday, setSunday] = useState(false)
  const [sundaySchodule, setSundaySchodule] = useState([1])
  const [schedule, setSchedule] = useState([])
  const [charge, setCharge] = useState(0)

  const user_id = id

  useEffect(() => {
    props.getAll(`schedules/${id}`, 'GET_DOCTOR_SCHEDULES').then(() => {
      props.getAll(`schedules/days/${id}`, 'GET_DOCTOR_INACTIVE_DAYS')
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (doctorSchedules && doctorSchedules.length > 0) {
      const days = [1, 2, 3, 4, 5, 6, 7]
      setSchedule(doctorSchedules)
       // eslint-disable-next-line
      days.map(day => {
        const scheduleDay = doctorSchedules.filter(
          schedule => schedule.day === day
        )
        if (day === 1 && scheduleDay.length > 0) {
          setMonday(true)
          setMondaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
        if (day === 2 && scheduleDay.length > 0) {
          setTuesday(true)
          setTuesdaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
        if (day === 3 && scheduleDay.length > 0) {
          setWednesday(true)
          setWednesdaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
        if (day === 4 && scheduleDay.length > 0) {
          setThursday(true)
          setThursdaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
        if (day === 5 && scheduleDay.length > 0) {
          setFriday(true)
          setFridaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
        if (day === 6 && scheduleDay.length > 0) {
          setSaturday(true)
          setSaturdaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
        if (day === 7 && scheduleDay.length > 0) {
          setSunday(true)
          setSundaySchodule(
            scheduleDay.filter(s => s.type === 0).map((s, index) => index + 1)
          )
        }
      })
    }
    setCharge(1)
  }, [doctorSchedules])

  const handlePushTime = (time, day, id, type, active) => {
    const newSchedule = schedule.filter(
      s => !(s.id === id && s.day === day && s.type === type)
    )
    newSchedule.push({ time, day, id, type, user_id, active })
    setSchedule(newSchedule)
  }

  const handleSaveSchedule = () => {
    const finalSchedule = schedule.filter(
      item => item.active === true || item.active === 1
    )
    if (finalSchedule.length > 0) {
      props
        .create(`schedules/${id}`, 'CREATE_DOCTOR_SCHEDULES', finalSchedule)
        .then(() => {
          props.getAll(`schedules/${id}`, 'GET_DOCTOR_SCHEDULES')
        })
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
      })
      Toast.fire({
        icon: 'info',
        title: 'El horario esta vacio',
      })
    }
  }

  const editable = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          newData.user_id = id
          props
            .create(`schedules/days/${id}`, 'CREATE_DAY', newData)
            .then(() =>
              props.getAll(`schedules/days/${id}`, 'GET_DOCTOR_INACTIVE_DAYS')
            )
            .then(() => resolve())
        }, 1000)
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          newData.user_id = id
          delete newData.id
          props
            .update(`schedules/days/${oldData.id}`, 'UPDATE_DAY', newData)
            .then(() =>
              props.getAll(`schedules/days/${id}`, 'GET_DOCTOR_INACTIVE_DAYS')
            )
            .then(() => resolve())
        }, 1000)
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          props
            .deleted(`schedules/days/${oldData.id}`, 'DELETE_DAY')
            .then(() =>
              props.getAll(`schedules/days/${id}`, 'GET_DOCTOR_INACTIVE_DAYS')
            )
            .then(() => resolve())
        }, 1000)
      }),
  }

  if (doctorSchedules && charge > 0 && doctorsInactiveDays) {
    return (
      <>
        <Card title="Horarios">
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Lunes</div>
              <div>
                <Switch
                  checked={monday}
                  onChange={() => setMonday(!monday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {mondaySchodule.map(item => {
              const time1 =
                schedule.find(
                  s => s.id === item && s.day === 1 && s.type === 0
                ) !== undefined
                  ? schedule.find(
                      s => s.id === item && s.day === 1 && s.type === 0
                    ).time
                  : null
              const time2 =
                schedule.find(
                  s => s.id === item && s.day === 1 && s.type === 1
                ) !== undefined
                  ? schedule.find(
                      s => s.id === item && s.day === 1 && s.type === 1
                    ).time
                  : null

              return (
                <div className="schedule__time" key={item}>
                  <TextField
                    id="time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 1800, // 5 min
                    }}
                    defaultValue={time1}
                    onChange={e =>
                      handlePushTime(e.target.value, 1, item, 0, monday)
                    }
                  />
                  <span>-</span>
                  <TextField
                    id="time"
                    type="time"
                    defaultValue={time2}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 1800, // 5 min
                    }}
                    onChange={e =>
                      handlePushTime(e.target.value, 1, item, 1, monday)
                    }
                  />

                  <FiTrash
                    onClick={() => {
                      setMondaySchodule(mondaySchodule.filter(i => i !== item))
                      setSchedule(
                        schedule.filter(s => !(s.id === item && s.day === 1))
                      )
                    }}
                  />
                </div>
              )
            })}
            <div
              className="schedule__add"
              onClick={() =>
                setMondaySchodule([
                  ...mondaySchodule,
                  mondaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Martes</div>
              <div>
                <Switch
                  checked={tuesday}
                  onChange={() => setTuesday(!tuesday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {tuesdaySchodule.map(item => (
              <div className="schedule__time" key={item}>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 2 && s.type === 0
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 2 && s.type === 0
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 2, item, 0, tuesday)
                  }
                />
                <span>-</span>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 2 && s.type === 1
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 2 && s.type === 1
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 2, item, 1, tuesday)
                  }
                />

                <FiTrash
                  onClick={() => {
                    setTuesdaySchodule(tuesdaySchodule.filter(i => i !== item))
                    setSchedule(
                      schedule.filter(s => !(s.id === item && s.day === 2))
                    )
                  }}
                />
              </div>
            ))}
            <div
              className="schedule__add"
              onClick={() =>
                setTuesdaySchodule([
                  ...tuesdaySchodule,
                  tuesdaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Miercoles</div>
              <div>
                <Switch
                  checked={wednesday}
                  onChange={() => setWednesday(!wednesday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {wednesdaySchodule.map(item => (
              <div className="schedule__time" key={item}>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 3 && s.type === 0
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 3 && s.type === 0
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 3, item, 0, wednesday)
                  }
                />
                <span>-</span>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 3 && s.type === 1
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 3 && s.type === 1
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 3, item, 1, wednesday)
                  }
                />

                <FiTrash
                  onClick={() => {
                    setWednesdaySchodule(
                      wednesdaySchodule.filter(i => i !== item)
                    )
                    setSchedule(
                      schedule.filter(s => !(s.id === item && s.day === 3))
                    )
                  }}
                />
              </div>
            ))}
            <div
              className="schedule__add"
              onClick={() =>
                setWednesdaySchodule([
                  ...wednesdaySchodule,
                  wednesdaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Jueves</div>
              <div>
                <Switch
                  checked={thursday}
                  onChange={() => setThursday(!thursday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {thursdaySchodule.map(item => (
              <div className="schedule__time" key={item}>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 4 && s.type === 0
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 4 && s.type === 0
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 4, item, 0, thursday)
                  }
                />
                <span>-</span>
                <TextField
                  id="time"
                  type="time"
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 4 && s.type === 1
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 4 && s.type === 1
                        ).time
                      : null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 4, item, 1, thursday)
                  }
                />

                <FiTrash
                  onClick={() => {
                    setThursdaySchodule(
                      thursdaySchodule.filter(i => i !== item)
                    )
                    setSchedule(
                      schedule.filter(s => !(s.id === item && s.day === 4))
                    )
                  }}
                />
              </div>
            ))}
            <div
              className="schedule__add"
              onClick={() =>
                setThursdaySchodule([
                  ...thursdaySchodule,
                  thursdaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Viernes</div>
              <div>
                <Switch
                  checked={friday}
                  onChange={() => setFriday(!friday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {fridaySchodule.map(item => (
              <div className="schedule__time" key={item}>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 5 && s.type === 0
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 5 && s.type === 0
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 5, item, 0, friday)
                  }
                />
                <span>-</span>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 5 && s.type === 1
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 5 && s.type === 1
                        ).time
                      : null
                  }
                  onChange={e =>
                    handlePushTime(e.target.value, 5, item, 1, friday)
                  }
                />

                <FiTrash
                  onClick={() => {
                    setFridaySchodule(fridaySchodule.filter(i => i !== item))
                    setSchedule(
                      schedule.filter(s => !(s.id === item && s.day === 5))
                    )
                  }}
                />
              </div>
            ))}
            <div
              className="schedule__add"
              onClick={() =>
                setFridaySchodule([
                  ...fridaySchodule,
                  fridaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Sabado</div>
              <div>
                <Switch
                  checked={saturday}
                  onChange={() => setSaturday(!saturday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {saturdaySchodule.map(item => (
              <div className="schedule__time" key={item}>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 6 && s.type === 0
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 6 && s.type === 0
                        ).time
                      : null
                  }
                  onChange={e =>
                    handlePushTime(e.target.value, 6, item, 0, saturday)
                  }
                />
                <span>-</span>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 6 && s.type === 1
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 6 && s.type === 1
                        ).time
                      : null
                  }
                  onChange={e =>
                    handlePushTime(e.target.value, 6, item, 1, saturday)
                  }
                />

                <FiTrash
                  onClick={() => {
                    setSaturdaySchodule(
                      saturdaySchodule.filter(i => i !== item)
                    )
                    setSchedule(
                      schedule.filter(s => !(s.id === item && s.day === 6))
                    )
                  }}
                />
              </div>
            ))}
            <div
              className="schedule__add"
              onClick={() =>
                setSaturdaySchodule([
                  ...saturdaySchodule,
                  saturdaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <div className="schedule__grid">
            <div className="schedule__day">
              <div>Domingo</div>
              <div>
                <Switch
                  checked={sunday}
                  onChange={() => setSunday(!sunday)}
                  name="checkedB"
                  color="primary"
                />
              </div>
            </div>
            {sundaySchodule.map(item => (
              <div className="schedule__time" key={item}>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 7 && s.type === 0
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 7 && s.type === 0
                        ).time
                      : null
                  }
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  onChange={e =>
                    handlePushTime(e.target.value, 7, item, 0, sunday)
                  }
                />
                <span>-</span>
                <TextField
                  id="time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 5 min
                  }}
                  defaultValue={
                    schedule.find(
                      s => s.id === item && s.day === 7 && s.type === 1
                    ) !== undefined
                      ? schedule.find(
                          s => s.id === item && s.day === 7 && s.type === 1
                        ).time
                      : null
                  }
                  onChange={e =>
                    handlePushTime(e.target.value, 7, item, 1, sunday)
                  }
                />

                <FiTrash
                  onClick={() => {
                    setSundaySchodule(sundaySchodule.filter(i => i !== item))
                    setSchedule(
                      schedule.filter(s => !(s.id === item && s.day === 7))
                    )
                  }}
                />
              </div>
            ))}
            <div
              className="schedule__add"
              onClick={() =>
                setSundaySchodule([
                  ...sundaySchodule,
                  sundaySchodule.length + 1,
                ])
              }
            >
              Agregar Horario
            </div>
          </div>
          <Button onClick={handleSaveSchedule}>Guardar</Button>
        </Card>
        <MaterialTable
          title="Días Inactivos"
          columns={[
            {
              title: 'Fecha',
              field: 'date',
            },
          ]}
          localization={localization}
          data={doctorsInactiveDays.map(day => {
            return {
              ...day,
              date: moment(day.date).format('YYYY-MM-DD'),
            }
          })}
          editable={editable}
          options={{ paging: false }}
        />
      </>
    )
  } else {
    return <Loading />
  }
}

const mapStateToProps = state => {
  return {
    doctorSchedules: state.reducerDoctors.doctorSchedules,
    doctorsInactiveDays: state.reducerDoctors.doctorsInactiveDays,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  deleted,
  setWraper,
}

export default connect(mapStateToProps, mapDispatchToProps)(Studies)
